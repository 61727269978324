import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Form, Button, FormCheck } from 'react-bootstrap'
import { STAT_URL } from '../utils'
import axios from '../axios'

const MemberSearch = ({ setSearch }) => {
  const forms = useRef()
  const [formKey, setFormKey] = useState(0)
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [tc, setTc] = useState('')
  const [tcConfirm, setTcConfirm] = useState(false)
  const [emailConfirm, setEmailConfirm] = useState(false)
  const [phoneConfirm, setPhoneConfirm] = useState(false)
  const [education, setEducation] = useState(null)
  const [district, setDistrict] = useState(null)
  const [city, setCity] = useState('BURSA')
  const [neighbourhood, setNeighbourhood] = useState(null)
  const [isStudent, setIsStudent] = useState(null)
  const [districtList, setDistrictList] = useState([])
  const [neighbourhoodList, setNeighbourhoodList] = useState([])
  const [countyId, setCountyId] = useState(0)
  const [cityList, setCityList] = useState([])
  const [cityId, setCityId] = useState(0)

  useEffect(() => {
    axios.get(`${STAT_URL}/citydata`).then((res) => {
      const data = res.data.data
      setCityList(data)
      cityList.map((cities, key) => {
        if (cities.name == city) {
          setCityId(cities.id)
        }
      })
    })
  }, [city])

  useEffect(() => {
    axios.get(`${STAT_URL}/member/district/detail/${cityId}`).then((res) => {
      const data = res.data.data
      setDistrictList(data)
  
      districtList.map((dis, key) => {
        if (dis.title == district) {
          setCountyId(dis.id)
        }
      })
    })
  }, [district,cityId])


  useEffect(() => {
    axios.get(`${STAT_URL}/member/district/${countyId}`).then((res) => {
      const data = res.data
     
      setNeighbourhoodList(data)
    })
  }, [countyId])
  useEffect(()=>{
    console.log(neighbourhood)
    console.log(education);
  })

  const send = () => {
    const all = []

    const items = []
    if (firstname !== '') {
      items.push({ title: 'firstname', value: firstname })
    }
    if (lastname !== '') {
      items.push({ title: 'lastname', value: lastname })
    }
    if (email !== '') {
      items.push({ title: 'email', value: email })
    }
    if (phone !== '') {
      items.push({ title: 'phone', value: phone })
    }
    if (tc !== '') {
      items.push({ title: 'tc', value: tc })
    }
    if (isStudent) {
      items.push({ title: 'is_student', value: isStudent })
    }

    if (education && education !== 'Eğitim Durumu Seç') {
      items.push({ title: 'education_status', value: education })
    }
    if (city && city !=='İl Seç') {
      items.push({ title: 'city', value: city })
    }
    if (district && district !=='İlçe Seç') {
      items.push({ title: 'county', value: district })
    }
    if (neighbourhood && neighbourhood !== 'Mahalle Seç') {
      items.push({ title: 'district', value: neighbourhood })
    }

    const conditions = []

    if (tcConfirm) {
      conditions.push({ val: 'tc_confirmed' })
    }
    if (emailConfirm) {
      conditions.push({ val: 'email_confirmed' })
    }
    if (phoneConfirm) {
      conditions.push({ val: 'phone_confirmed' })
    }

    all.push(items)
    all.push(conditions)

    setSearch(all)
  }
  useEffect(()=>{
    send();
  },[])

  const reset = () => {
    setFirstname('')
    setCity(null)
    setIsStudent(null)
    setEducation(null)
    setNeighbourhood(null)
    setDistrict(null)
    setLastname('')
    setEmail('')
    setPhone('')
    setTc('')
    setPhoneConfirm(false)
    setTcConfirm(false)
    setEmailConfirm(false)
    setFormKey(formKey + 1)
  }

  return (
    <>
      <Form key={formKey}>
        <Row className={'pb-4'}>
          <Col md={2}>
            <Form.Control
              type="text"
              placeholder="İsim"
              defaultValue={firstname}
              onChange={(e) => setFirstname(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              type="text"
              placeholder="Soyisim"
              defaultValue={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              type="text"
              placeholder="email"
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              type="text"
              placeholder="Telefon"
              defaultValue={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <Form.Control
              type="text"
              placeholder="Tc"
              defaultValue={tc}
              onChange={(e) => setTc(e.target.value)}
            />
          </Col>
          <Col md={2}>
            <Button
              variant={'primary'}
              className={'w-100'}
              onClick={() => send()}
            >
              Ara
            </Button>
          </Col>
        </Row>
        <Row className={'pb-4'}>
          <Col md={1}>
            <FormCheck
              label={'Tc Onaylı'}
              onClick={(e) => setTcConfirm(!tcConfirm)}
              ref={forms}
              defaultChecked={tcConfirm}
            />
          </Col>
          <Col md={1}>
            <FormCheck
              label={'Email Onaylı'}
              defaultChecked={emailConfirm}
              ref={forms}
              onClick={(e) => setEmailConfirm(!emailConfirm)}
            />
          </Col>
          <Col md={1}>
            <FormCheck
              label={'Telefon Onaylı'}
              defaultChecked={phoneConfirm}
              ref={forms}
              onClick={(e) => setPhoneConfirm(!phoneConfirm)}
            />
          </Col>
          <Col md={7}>
            <Col md={12} style={{ display: 'flex' }}>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setEducation(e.target.value)}
              >
                <option >Eğitim Durumu Seç</option>
                <option >İlköğretim</option>
                <option >Ortaokul</option>
                <option >Lise (Öğrenci)</option>
                <option >Lise (Mezun)</option>
                <option >Önlisans (Öğrenci)</option>
                <option >Önlisans (Mezun)</option>
                <option >Lisans (Öğrenci)</option>
                <option >Lisans (Mezun)</option>
                <option >Yüksek Lisans (Öğrenci)</option>
                <option >Yüksek Lisans (Mezun)</option>
                <option >Doktora (Öğrenci)</option>
                <option >Doktora (Mezun)</option>
              </Form.Select>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setCity(e.target.value)}
              >
                <option  >İl Seç</option>
                <option value='BURSA'id={16} selected >BURSA</option>
                {cityList.map((cities, key) => {
                  if (cities.name !== 'BURSA') {
                    return (
                    
                      <option key={key} id={cities.id} value={cities.name}>
                        {cities.name}
                      </option>
                    )
                  }
                
                })}
              </Form.Select>
              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setDistrict(e.target.value)}
              >
                <option>İlçe Seç</option>
                {districtList.map((county, key) => {
                  return (
                    <option key={key} id={county.id} value={county.title}>
                      {county.title}
                    </option>
                  )
                })}
              </Form.Select>

              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setNeighbourhood(e.target.value)}
              >
                <option>Mahalle Seç</option>
                {neighbourhoodList.map((hood, key) => {
                  return (
                    <option key={key} value={hood.title}>
                      {hood.title}
                    </option>
                  )
                })}
              </Form.Select>
            </Col>
          </Col>
          {/* <Col md={2}>
            <FormCheck
              label={'Öğrenci'}
              inline
              type={'radio'}
              id={'is_student'}
              name={'is_student'}
              value={1}
              onClick={(e) => setIsStudent(e.target.value)}
            />
            <FormCheck
              label={'Mezun'}
              inline
              type={'radio'}
              id={'is_student'}
              name={'is_student'}
              value={0}
              onClick={(e) => setIsStudent(e.target.value)}
            />
     
          </Col> */}
          <Col md={2}>
            <Button
              variant={'warning'}
              className={'w-100'}
              style={{ float: 'right' }}
              onClick={() => reset()}
            >
              Sıfırla
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default MemberSearch

import react, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Button, Table, ListGroup } from 'react-bootstrap';
import ProgressBarCard from '../../components/progressBar';
import { STAT_URL } from "../../utils";
import axios from '../../axios';
import AgeList from '../../components/ageList';
import { Link } from 'react-router-dom';
import member from '../../store/member';

const Detail = (props) => {

    const [data, setData] = useState(null);
    const [member, setMember] = useState({});
    const [campaigns,setCampaigns] = useState([]);
    const [events,setEvents] = useState([]);
    const [locations,setLocations] = useState([]);

    useEffect(() => {
        const id = props.match.params.id
        axios.get(`${STAT_URL}/member/detail/${id}`)
            .then((res) => {
                const data = res.data.data;
                setMember(data.member)
                setCampaigns(data.campaigns);
                setEvents(data.events);
                setLocations(data.locations);
            })
    }, [])


    const exportExcel = () => {
        axios({
            url: `${STAT_URL}/member/detail_excel/${props.match.params.id}`,
            method: 'GET',
            responseType: 'blob',
        })
            .then((res) => {
                var FILE = window.URL.createObjectURL(new Blob([res.data]));
                var docUrl = document.createElement("a");
                docUrl.href = FILE;
                docUrl.setAttribute('download', `location.xls`);
                let add = document.body.appendChild(docUrl);
                add.click();
            })
    }

    const getGender = (gender) => {
        switch (gender) {
            case 'male':
                return 'Erkek';
                break;
            case 'female':
                return 'Kadın'
            default:
                return 'Belirtilmemiş'
                break;
        }
    }


    return (
        <Container fluid>
            <Row className={"mt-3"}>
                <Col md={12} sm={12}>
                    {
                        <Card>
                            <Card.Body>
                                <Button variant={"success"} style={{ float: 'right' }} onClick={() => exportExcel()}> Excele Aktar </Button>
                                <Card.Title>{member.firstname}  {member.lastname}</Card.Title>
                                <ListGroup as="ul" className={'pt-4'}>
                                    <ListGroup.Item as="li" > Email :  {member.email}</ListGroup.Item>
                                    <ListGroup.Item as="li" > Telefon :  {member.phone}</ListGroup.Item>
                                    <ListGroup.Item as="li" > Doğum Tarihi : {member.birthday}</ListGroup.Item>
                                    <ListGroup.Item as="li" > Tc : {member.tc}</ListGroup.Item>
                                    <ListGroup.Item as="li" > Cinsiyet : {getGender(member.gender)}</ListGroup.Item>
                                    <ListGroup.Item as="li" > Qr Kod : {member.code}</ListGroup.Item>
                                    <ListGroup.Item as="li" > Referans Kod : {member.reference_code}</ListGroup.Item>
                                    <ListGroup.Item as="li" > İlçe : {member.district}</ListGroup.Item>
                                    <ListGroup.Item as="li" > Mahalle : {member.county}</ListGroup.Item>
                                    <ListGroup.Item as="li" > Adres : {member.address}</ListGroup.Item>
                                    <ListGroup.Item as="li" > Tc Doğrulandı : {member.tc_confirmed === 1 ? 'Evet' : 'Hayır'}</ListGroup.Item>
                                    <ListGroup.Item as="li" > Email Doğrulandı : {member.email_confirmed === 1 ? 'Evet' : 'Hayır'}</ListGroup.Item>
                                    <ListGroup.Item as="li" > Telefon Doğrulandı : {member.phone_confirmed === 1 ? 'Evet' : 'Hayır'}</ListGroup.Item>
                                </ListGroup>
                            </Card.Body>
                        </Card>
                    }
                </Col>
            </Row>

            <Row className={"mt-3"}>
                <Col md={12} sm={12}>
                    {campaigns &&
                        <Card>
                            <Card.Body>
                            <Card.Title>Kampanyalar</Card.Title>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Kampanya Adı</th>
                                            <th>Kullanıldı</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {campaigns.map((campaign) => (
                                            <tr key={`campaign-${campaign.id}`}>
                                                <td>{campaign.title}</td>
                                                <td>
                                                    {campaign.confirmed === 1 ? 'Evet' : "Hayır"}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    }
                </Col>
                <Col md={12} sm={12} className='pt-5'>
                    {events &&
                        <Card>
                            <Card.Body>
                            <Card.Title>Etkinlikler</Card.Title>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Etkinlik Adı</th>
                                            <th>Kullanıldı</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {events.map((event) => (
                                            <tr key={`event-${event.id}`}>
                                                <td>{event.title}</td>
                                                <td>
                                                    {event.confirmed === 1 ? 'Evet' : "Hayır"}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    }
                </Col>
                <Col md={12} sm={12} className='pt-5'>
                    {locations &&
                        <Card>
                            <Card.Body>
                            <Card.Title>Tesisler</Card.Title>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Tesis Adı</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {locations.map((location) => (
                                            <tr key={`location-${location.id}`}>
                                                <td>{location.title}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    }
                </Col>
            </Row>
        </Container>
    );
}


export default Detail;
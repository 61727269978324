import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../store/auth";
import { ReactSVG } from "react-svg";
import { withRouter } from "react-router-dom";

const Login = (props) => {
  const auth = useSelector((s) => s.Auth);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (auth) {
      if (auth.loginStatus) {
        props.history.push('/');
      }
    }
  }, [auth]);

  const sendPost = () => {
    const data = new FormData();
    data.append("email", email);
    data.append("password", password);
    dispatch(login(data));
  };

  return (
    <Container style={{ height: "100vh" }}>
      <Row
        className="justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Col md={4} sm={12}>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-center">
                <ReactSVG src="logo.svg" />
              </div>
              <Form>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type={"text"}
                    defaultValue={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Şifre</Form.Label>
                  <Form.Control
                    type={"password"}
                    defaultValue={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  {auth.error && (
                    <span
                      className={"p-3 text-center"}
                      style={{ color: "red" }}
                    >
                      Kullanıcı adı ya da şifreniz yanlış.
                    </span>
                  )}
                </Form.Group>
                <Form.Group>
                  <Button
                    variant="success"
                    className={"w-100 mt-4"}
                    onClick={() => sendPost()}
                  >
                    Giris
                  </Button>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(Login);

import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table, Button, ProgressBar, Pagination, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import TableSkeleton from "../../components/skeletons/tableSkeleton";
import { fetchData } from "../../store/member";
import { STAT_URL } from "../../utils";
import axios from '../../axios';
import { Link } from "react-router-dom";
import MemberSearch from "../../components/memberSearch";

const Index = () => {
  const state = useSelector((s) => s.Member);
  const [totalUser, setTotalUser] = useState(0);
  const [filteredUser,setFilteredUser] = useState();
  const [genders, setGenders] = useState({});
  const [members, setMembers] = useState([]);
  const [pages, setPages] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [paginatorData, setPaginatorData] = useState([]);
  const [search, setSearch] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData());
  }, []);

  useEffect(() => {
    if (!state.loading) {
      setTotalUser(state.data.totalUser);
      setGenders(state.data.genders);
      setMembers(state.data.members);
      setPages(state.data.pages);
      setFilteredUser(state.data.filtered_user)
    }
  }, [state])


  useEffect(() => {
    trigger()
  }, [activePage, search])

  const exportExcel = (data) => {
    axios({
      url: `${STAT_URL}/member/all_excel/get`,
      method: 'POST',
      responseType: 'blob',
      data: data
    })
      .then((res) => {
        var FILE = window.URL.createObjectURL(new Blob([res.data]));
        var docUrl = document.createElement("a");
        docUrl.href = FILE;
        docUrl.setAttribute('download', `member.xls`);
        let add = document.body.appendChild(docUrl);
        add.click();
      })
  }

  const trigger = (excel = null) => {
    const form = new FormData();
    form.append('page', activePage);
    if (search.length > 0) {
      search[0].map((s, key) => {
        form.append(`search[${key}][column]`, s.title);
        form.append(`search[${key}][value]`, s.value);
      })

      search[1].map((s) => {
        form.append(`conditions[]`, s.val);
      })
    }

    if (excel) {
      form.append(`excel`, 1);
      exportExcel(form);
      return;
    }

    dispatch(fetchData(form))
  }


  const prepareSearch = (items) => {
    setActivePage(1);
    setSearch(items);
  }

  useEffect(() => {
    const items = [];

    for (let number = activePage - 3; number < activePage; number++) {
      if (number > 0) {
        items.push(
          <Pagination.Item key={number} onClick={() => setActivePage(number)} active={number === activePage}>
            {number}
          </Pagination.Item>,
        );
      }

    }

    for (let number = activePage; number <= activePage + 3; number++) {
      if (number <= pages.total_page) {
        items.push(
          <Pagination.Item key={number} onClick={() => setActivePage(number)} active={number === activePage}>
            {number}
          </Pagination.Item>,
        );
      }
    }

    setPaginatorData(items);
  }, [pages])




  return (
    <>
      <Container fluid>
        <Row>
          <Col md={12} sm={12} className={"pt-5"}>
            <Button variant={"success"} style={{ float: 'right' }} onClick={() => trigger(1)}> Excele Aktar </Button>
          </Col>
          <Col md={12} sm={12} className={'pt-5 pb-4'}>
            <span>Toplam Kullanıcı : {totalUser}</span> <br/>
            <span>Filtrelenen Kullanıcı : {filteredUser}</span>
            <ProgressBar>
              <ProgressBar striped variant="primary" now={genders.male} label={`${genders.male}% Erkek`} key={1} />
              <ProgressBar variant="info" now={genders.noGender} key={2} label={`${genders.noGender}% Cinsiyet Belirtilmemiş`} />
              <ProgressBar striped variant="danger" now={genders.females} key={3} label={`${genders.females}% Kadın`} />
            </ProgressBar>
          </Col>
        </Row>
        <MemberSearch setSearch={prepareSearch} />
      </Container>
      {state && state.loading === true ? (
        <TableSkeleton />
      ) : (
        <Container fluid>

          <Row>
            <Col md={12} sm={12}>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>İsim Soyisim</th>
                    <th>Tc</th>
                    <th>Email</th>
                    <th>Telefon</th>
                    <th>Cinsiyet</th>
                    <th>Doğum Tarihi</th>
                    <th>Tc Onaylandı</th>
                    <th>Email Onaylandı</th>
                    <th>Telefon Onaylandı</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    members.map((member) => (
                      <tr key={member.id}>
                        <td> {member.fullname} </td>
                        <td> {member.tc} </td>
                        <td> {member.email} </td>
                        <td> {member.phone} </td>
                        <td> {member.gender} </td>
                        <td> {member.birthday} </td>
                        <td> {member.tc_confirmed === 1 ? 'Evet' : 'Hayır'} </td>
                        <td> {member.email_confirmed === 1 ? 'Evet' : 'Hayır'} </td>
                        <td> {member.phone_confirmed === 1 ? 'Evet' : 'Hayır'} </td>
                        <td>
                        <Link to={`member/${member.id}`}>
                          <Button variant='primary' size="sm">İncele</Button>
                        </Link></td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
              <Col>


                < Pagination >

                  {
                    activePage > 1 ?
                      <>
                        <Pagination.First onClick={() => setActivePage(1)} />
                        <Pagination.Prev onClick={() => setActivePage(activePage != 1 ? activePage - 1 : 1)} />
                      </>
                      : ''}

                  <Pagination>{paginatorData}</Pagination>

                  {
                    activePage < pages.total_page ?
                      <>
                        <Pagination.Next onClick={() => setActivePage(activePage != pages.total_page ? activePage + 1 : pages.total_page)} />
                        <Pagination.Last onClick={() => setActivePage(pages.total_page)} />

                      </> : ""
                  }

                </Pagination>

              </Col>
            </Col>
          </Row>
        </Container>
      )
      }
    </>
  );
};

export default Index;

import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./auth";
import StatReducer from "./home";
import CampaignReducer from "./campaign";
import EventReducer from "./event";
import SurveyReducer from "./survey";
import LocationReducer from "./location";
import MemberReducer from "./member";

const store = configureStore({
  reducer: {
    Auth: AuthReducer,
    Home: StatReducer,
    Campaign: CampaignReducer,
    Event: EventReducer,
    Survey: SurveyReducer,
    Location: LocationReducer,
    Member:MemberReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

export default store;

import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "./sidebar";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { me } from "../../store/auth";

const Default = (props) => {

    const state = useSelector((state) => state.Auth);
    const dispatch = useDispatch();

    useEffect(() => {
        if (state.error && !state.loginStatus) {
            props.history.push('/login');
        }
    }, [])

    return (
        <>
            <div className={'d-flex'}>
                <div style={{ minWidth: '300px', float: 'left' }}>
                    <Sidebar />
                </div>
                <div style={{ float: 'left', width: '100%' }}>
                    <Container fluid>
                        <Row>
                            <Col md={12}>
                                {props.children}
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>

    );
}

export default withRouter(Default);
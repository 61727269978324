import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import {
  FaCalendarAlt,
  FaBoxTissue,
  FaClipboard,
  FaChromecast,
  FaLocationArrow,
  FaUserAlt
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { removeToken, me } from "../../store/auth";

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const state = useSelector((s) => s.Auth);
  const storage = localStorage.getItem('appState');
  
  const logout = () => {
    dispatch(removeToken());
    window.location.href = '/login';
  };

  useEffect(() => {
    if (storage) {
        dispatch(me());
    }
  }, [storage])



  const links = useState([
    {
      title: "Anasayfa",
      to: "/",
      icon: <FaChromecast />,
    },
    {
      title: "Etkinlikler",
      to: "/event",
      icon: <FaCalendarAlt />,
    },
    {
      title: "Kampanyalar",
      to: "/campaign",
      icon: <FaBoxTissue />,
    },
    ,
    {
      title: "Tesisler",
      to: "/location",
      icon: <FaLocationArrow />,
    },
    {
      title: "Anketler",
      to: "/survey",
      icon: <FaClipboard />,
    },
    ,
    {
      title: "Üyeler",
      to: "/member",
      icon: <FaUserAlt />,
    },
  ]);

  return (
    <Container
      fluid
      style={{ borderRight: "1px solid #ddd", height: "100%" }}
      className={"pt-2"}
    >
      <Row>
        <Col
          md={12}
          style={{ borderBottom: "1px solid #ddd" }}
          className={"pb-4"}
        >
          <span className={"title"}>{state.user && state.user.name}</span>{" "}
          <br />
          <span className={"subtitle"}>
            {state.user && state.user.email}
          </span>{" "}
          <br />
          <Button
            variant="outline-primary"
            className="mt-3"
            onClick={() => logout()}
          >
            Çıkış Yap
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {links[0].map((link) => (
            <div key={link.title} className={"sidebar-links"}>
              <Link to={link.to}>
                <div>
                  <span>{link.icon}</span>
                  <span className={"sidebar-title"}>{link.title}</span>
                </div>
              </Link>
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
};

export default (Sidebar);

import react, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Button, Table } from 'react-bootstrap';
import ProgressBarCard from '../../components/progressBar';
import { STAT_URL } from "../../utils";
import axios from '../../axios';
import AgeList from '../../components/ageList';
import { Link } from 'react-router-dom';

const Detail = (props) => {

    const [data, setData] = useState(null);
    useEffect(() => {
        const id = props.match.params.id
        axios.get(`${STAT_URL}/location/${id}`)
            .then((res) => {
                setData(res.data[0]);
            })
    }, [])


    const exportExcel = () => {
        axios({
            url: `${STAT_URL}/location/detail_excel/${props.match.params.id}`,
            method: 'GET',
            responseType: 'blob',
        })
            .then((res) => {
                var FILE = window.URL.createObjectURL(new Blob([res.data]));
                var docUrl = document.createElement("a");
                docUrl.href = FILE;
                docUrl.setAttribute('download', `location.xls`);
                let add = document.body.appendChild(docUrl);
                add.click();
            })
    }


    return (
        <Container fluid>
            <Row className={"mt-3"}>
                <Col md={12} sm={12}>
                    {data &&
                        <Card>
                            <Card.Body>
                                <Button variant={"success"} style={{ float: 'right' }} onClick={() => exportExcel()}> Excele Aktar </Button>
                                <Card.Title>{data.title}</Card.Title>
                                <div className={"mt-3 text-center"}>
                                    <h5>Katılımcılar : {data.stats.participation.total} </h5>
                                </div>
                                <ProgressBarCard data={data.stats.participation} />

                                <div className='text-center mt-5'>
                                    <h6>Yaşlara Göre Giriş Sayısı</h6>
                                </div>

                                <Row>
                                    <Col md={6} sm={6}>
                                        <div className={'w-100 d-flex justify-content-center'}>
                                            <div>
                                                <AgeList data={data.stats.participation.male.ages} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6} sm={6}>
                                        <div className={'w-100 d-flex justify-content-center'}>
                                            <div>
                                                <AgeList data={data.stats.participation.female.ages} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    }
                </Col>
            </Row>

            <Row className={"mt-3"}>
                <Col md={12} sm={12}>
                    {data &&
                        <Card>
                            <Card.Body>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Tesise Giriş Yapan Kullanıcılar </th>
                                            <th>#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.stats.pariticipation_list.map((user) => (
                                            <tr key={`user-${user.id}`}>
                                                <td>{user.firstname} {user.lastname}</td>
                                                <td>
                                                    <Link to={`location/${data.id}`}>
                                                        <Button variant='primary' size="sm">Kullanıcıyı Görüntüle</Button>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    }
                </Col>
            </Row>
        </Container>
    );
}


export default Detail;
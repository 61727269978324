import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Route, withRouter } from "react-router-dom";

import Default from "../components/layout/default";
import HomeIndex from "../pages/home/index";
import EventIndex from "../pages/event/index";
import CampaignIndex from "../pages/campaign/index";
import SurveyIndex from "../pages/survey/index";
import LocationIndex from "../pages/location/index";
import MemberIndex from "../pages/member/index";

import CampaignDetail from '../pages/campaign/detail';
import EventDetail from '../pages/event/detail';
import LocationDetail from '../pages/location/detail';
import SurveyDetail from '../pages/survey/detail';
import MemberDetail from '../pages/member/detail';

const PrivateRoutes = () => {
    return (
        <Router>
            <Default>
                <Route exact path="/" component={HomeIndex} />
                <Route exact path="/event" component={EventIndex} />
                <Route  path="/event/:id" component={EventDetail} />
                <Route exact path="/campaign" component={CampaignIndex} />
                <Route  path="/campaign/:id" component={CampaignDetail} />
                <Route exact path="/survey" component={SurveyIndex} />
                <Route path="/survey/:id" component={SurveyDetail} />
                <Route exact path="/location" component={LocationIndex} />
                <Route path="/location/:id" component={LocationDetail} />
                <Route exact path="/member" component={MemberIndex} />
                <Route path="/member/:id" component={MemberDetail} />
            </Default>
        </Router>

    );
};

export default PrivateRoutes;

import react, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import Chart from "react-google-charts";
const ChartCard = ({ data }) => {
  const [prepareData, setPrepareData] = useState([]);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const res = [];
    res.push(["Task", "Hours per Day"]);
    for (const [key, element] of Object.entries(data.data.paritipication)) {
      if (key !== "total") {
        res.push([key, element]);
      }
    }
    prepareData.push(res);
    setStatus(true);
  }, [data]);
  return (
    <Card className={'mt-5'}>
      <Card.Body>
        <Card.Title>{data.title}</Card.Title>
        <span>Toplam : {data.data.total}</span> <br />
        <span>Yayında : {data.data.published}</span> <br />
        <span>Yayında Olmayan : {data.data.unpublished}</span>
        <Row>
          <Col md={6} sm={12}>
            {status && (
              <Chart
                width={"500px"}
                height={"300px"}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={prepareData[0]}
                options={{
                  title: "Katılımcılar",
                }}
                rootProps={{ "data-testid": "1" }}
              />
            )}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ChartCard;

import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom'
import Login from "../pages/Login";
import PrivateRoutes from "./privateRoutes";

const Routes = () => {

    return (
        <>
        <Router>
            <Switch>
                <Route path='/login'>
                    <Login />
                </Route>
             <PrivateRoutes></PrivateRoutes>
            </Switch>
        </Router>
        </>
    );
}

export default Routes;
import react, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';

const ProgressBarCard = (data) => {
    const [male, setMale] = useState(0);
    const [female, setFemale] = useState(0);
    const [noGender, setNoGender] = useState(0);

    useEffect(() => {
        setMale(data.data.male.percentage);
        setFemale(data.data.female.percentage);
        setNoGender(data.data.no_gender.percentage);
    }, [data])

    return (
        <ProgressBar>
            <ProgressBar striped variant="primary" now={male} label={`${male}% Erkek`} key={1} />
            <ProgressBar variant="info" now={noGender} key={2} label={`${noGender}% Cinsiyet Belirtilmemiş`} />
            <ProgressBar striped variant="danger" now={female} key={3} label={`${female}% Kadın`} />
        </ProgressBar>
    );

}

export default ProgressBarCard;
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../store/home";
import ChartCard from "../../components/chartCard";
import HomeSkeleton from "../../components/skeletons/homeSkeleton";
import { me } from "../../store/auth";

const Index = () => {
  const state = useSelector((s) => s.Home);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData());
  }, []);

  return (
    <>
      {state && state.loading === true ? (
        <HomeSkeleton />
      ) : (
        <Container fluid>
          <Row>
            <Col md={12} sm={12}>
              {state &&
                state.data.map((data) => (
                  <ChartCard data={data} key={data.title} />
                ))}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Index;

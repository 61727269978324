import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios";
import { STAT_URL } from "../utils";

export const fetchData = createAsyncThunk("MemberStat/data", async (data) => {
  const response = axios.post(`${STAT_URL}/member`,data);
  return response;
});

const statSlice = createSlice({
  name: "MemberStat",
  initialState: {
    data: [],
    loading: true,
    error: false,
  },
  reducers: {},
  extraReducers: {
    [fetchData.pending]: (state) => {
      state.data = [];
      state.loading = true;
    },
    [fetchData.fulfilled]: (state, action) => {
      state.data = action.payload.data.data;
      state.loading = false;
    },
    [fetchData.rejected]: (state) => {
      state.data = [];
      state.loading = false;
      state.error = true;
    },
  },
});

export default statSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axios";
import { STAT_URL } from "../utils";

export const fetchData = createAsyncThunk("HomeStat/data", async () => {
    const response = axios.get(`${STAT_URL}`);
    return response;
});

const statSlice = createSlice({
  name: "HomeStat",
  initialState: {
    data: [],
    loading: true,
    error: false,
  },
  reducers: {},
  extraReducers: {
    [fetchData.pending]: (state) => {
      state.data = [];
      state.loading = true;
    },
    [fetchData.fulfilled]: (state, action) => {
      try {
        const result = [];
        for (const [key, element] of Object.entries(action.payload.data.data)) {
          result.push({ title: element.title, data: element });
        }
        state.data = result;
      } catch (e) {
        state.data = action.payload.data;
      }

      state.loading = false;
    },
    [fetchData.rejected]: (state) => {
      state.data = [];
      state.loading = false;
      state.error = true;
    },
  },
});

export default statSlice.reducer;

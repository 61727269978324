import React, { useEffect, useState } from 'react';

const AgeList = ({ data }) => {

    const [state, setState] = useState([]);

    useEffect(() => {
        const rest = [];
        for (const [key, value] of Object.entries(data)) {
            rest.push({ key: key, val: value })
        }
        const sort = rest.sort()
        setState(sort);
    }, [])
    return (
        <>
            {
                state.map((item) => (
                    <>
                        <span key={`${item.key}-${item.value}`}> {item.key} - {item.val}</span> <br />
                    </>
                ))
            }

        </>
    );
}


export default AgeList;
import react, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Button } from 'react-bootstrap';
import ProgressBarCard from '../../components/progressBar';
import { STAT_URL } from "../../utils";
import axios from '../../axios';
import AgeList from '../../components/ageList';

const Detail = (props) => {

    const [data, setData] = useState(null);
    useEffect(() => {
        const id = props.match.params.id
        axios.get(`${STAT_URL}/campaign/${id}`)
            .then((res) => {
                setData(res.data[0]);
            })
    }, [])

    const exportExcel = () => {
        axios({
            url: `${STAT_URL}/campaign/detail_excel/${props.match.params.id}`,
            method: 'GET',
            responseType: 'blob',
        })
            .then((res) => {
                var FILE = window.URL.createObjectURL(new Blob([res.data]));
                var docUrl = document.createElement("a");
                docUrl.href = FILE;
                docUrl.setAttribute('download', `campaign.xls`);
                let add = document.body.appendChild(docUrl);
                add.click();
            })
    }

    return (
        <Container fluid>
            <Row className={"mt-3"}>
                <Col md={12} sm={12} className={'pt-5'}>
                    {data &&
                        <Card>
                            <Card.Body>
                            <Button variant={"success"} style={{ float: 'right' }} onClick={() => exportExcel()}> Excele Aktar </Button>
                                <Card.Title>{data.title}</Card.Title>
                                <div className={"mt-3 text-center"}>
                                    <h5>Katılımcılar : {data.stats.participation.total} </h5>
                                </div>
                                <ProgressBarCard data={data.stats.participation} />

                                <div className='text-center mt-5'>
                                    <h6>Yaşlara Göre Kullanım Sayısı</h6>
                                </div>

                                <Row>
                                    <Col md={6} sm={6}>
                                        <div className={'w-100 d-flex justify-content-center'}>
                                            <div>
                                                <AgeList data={data.stats.participation.male.ages} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6} sm={6}>
                                        <div className={'w-100 d-flex justify-content-center'}>
                                            <div>
                                                <AgeList data={data.stats.participation.female.ages} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <div className={"mt-3 text-center"}>
                                    <h5>Kullananlar : {data.stats.confirmed.total}</h5>
                                </div>
                                <ProgressBarCard data={data.stats.confirmed} />

                                <div className='text-center mt-5'>
                                    <h6>Yaşlara Göre Kullanım Sayısı</h6>
                                </div>

                                <Row>
                                    <Col md={6} sm={6}>
                                        <div className={'w-100 d-flex justify-content-center'}>
                                            <div>
                                                <AgeList data={data.stats.confirmed.male.ages} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6} sm={6}>
                                        <div className={'w-100 d-flex justify-content-center'}>
                                            <div>
                                                <AgeList data={data.stats.confirmed.female.ages} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    }
                </Col>
            </Row>
        </Container>
    );
}


export default Detail;
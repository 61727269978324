import react, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Button, Modal, ListGroup } from 'react-bootstrap';
import axios from '../../axios';
import { STAT_URL } from '../../utils';
import SurveyChart from '../../components/surveyChart';

const Detail = (props) => {

    const [data, setData] = useState(null);
    const [total, setTotal] = useState();
    const [totalAge, setTotalAge] = useState();
    const [questions, setQuestion] = useState([]);
    const [textQuestions, setTextQuestion] = useState([]);
    const [detailChart, setDetailChart] = useState([]);
    const [show, setShow] = useState(false);
    const [answers, setAnswers] = useState([]);

    useEffect(() => {
        const id = props.match.params.id
        axios.get(`${STAT_URL}/survey/${id}`)
            .then((res) => {
                setData(res.data[0]);
                const totalres = [];
                totalres.push(["Task", "Hours per Day"]);
                totalres.push(["Erkek", res.data[0].maleAnswer]);
                totalres.push(["Kadın", res.data[0].femaleAnswer]);
                totalres.push(['Cinsiyet Belirtilmemiş', res.data[0].noGenderAnswer]);
                setTotal(totalres);

                const totalAgeRes = [];
                totalAgeRes.push(["Task", "Hours per Day"]);
                totalAgeRes.push(["Genel Yaş Ortalaması", res.data[0].totalAgeAverage]);
                totalAgeRes.push(["Erkek Yaş Ortalaması", res.data[0].maleAgeAverage]);
                totalAgeRes.push(['Kadın Yaş Ortalaması', res.data[0].femalelAgeAverage]);
                setTotalAge(totalAgeRes);

                const questionRes = [];
                let textres = [];
                res.data[0].questions.map((q) => {

                    if (q.questionType === 'MultipleSelectionGroup') {
                        let res = [];
                        res.push(["Task", "Hours per Day"])
                        q.stat.map((s) => {
                            res.push([s.title, s.femaleAnswer + s.maleAnswer + s.noGenderAnswer]);
                        })

                        questionRes.push({ title: q.title, id: q.id, stat: res });
                        setQuestion(questionRes);
                    } else {
                        textres.push(q);
                    }

                })
                setTextQuestion(textres);
            })
    }, [])


    const setModal = (id, type) => {
        if (type === 'chart') {
            const question = data.questions.filter((q) => q.id === id);
            const detailRes = [];

            question[0].stat.map((s) => {
                const res = [];
                res.push(["Task", "Hours per Day"]);
                res.push(["Erkek", s.maleAnswer]);
                res.push(["Kadın", s.femaleAnswer]);
                res.push(['Cinsiyet Belirtilmemiş', s.noGenderAnswer]);
                detailRes.push({ title: s.title, stat: res });
            })

            setDetailChart(detailRes);

        } else {
            fetchAnswers(id);
        }

        setShow(!show);
    }

    const closeModal = () => {
        setShow(false);
        setDetailChart([]);
        setAnswers([]);
    }

    const fetchAnswers = (id) => {
        axios.get(`${STAT_URL}/survey/survey/answers/${id}`)
            .then((res) => {
                setAnswers(res.data.data);
            })

    }

    const exportExcel = () => {
        axios({
            url: `${STAT_URL}/survey/detail_excel/${props.match.params.id}`,
            method: 'GET',
            responseType: 'blob',
        })
            .then((res) => {
                var FILE = window.URL.createObjectURL(new Blob([res.data]));
                var docUrl = document.createElement("a");
                docUrl.href = FILE;
                docUrl.setAttribute('download', `survey.xls`);
                let add = document.body.appendChild(docUrl);
                add.click();
            })
    }

    return (
        <Container fluid>
            <Row className={"mt-3"}>
                <Col md={12} sm={12}>
                    {data &&
                        <Card>
                            <Card.Body>
                            <Button variant={"success"} style={{ float: 'right' }} onClick={() => exportExcel()}> Excele Aktar </Button>
                                <Row>
                                    <Col md={6} sm={12}>
                                        <h6>Toplam Katılımcı Sayısı : {data.totalAttendance}</h6>
                                        <SurveyChart data={total} />
                                    </Col>
                                    <Col md={6} sm={12}>
                                        <h6>Yaş Ortalaması : {data.totalAgeAverage}</h6>
                                        <SurveyChart data={totalAge} />
                                    </Col>
                                </Row>
                                <Row className={"pt-5"}>
                                    {questions.map((s) => (
                                        <Col md={6} sm={12} key={s.title}>
                                            <h6>{s.title} </h6>
                                            <SurveyChart data={s.stat} showButton modal={setModal} id={s.id} />
                                        </Col>
                                    ))}
                                </Row>

                                <Row className={"pt-5"}>
                                    {
                                        textQuestions.map((q) => (
                                            <Col sm={12} md={4} lg={4} key={q.id}>
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title>{q.title}</Card.Title>
                                                        <Button color='primary' style={{ float: 'right' }} size='sm' onClick={() => setModal(q.id, 'list')}>Cevapları Gör</Button>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        ))
                                    }

                                </Row>

                                <Modal show={show} fullscreen onHide={() => closeModal()}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Detaylar</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Row>
                                            {detailChart.map((s) => (
                                                <Col md={6} sm={12} className='pt-3' key={s.title}>
                                                    <h6>{s.title} </h6>
                                                    <SurveyChart data={s.stat} modal={setModal} id={s.id} />
                                                </Col>
                                            ))}
                                            <ListGroup>
                                                {
                                                    answers.map((a) => (
                                                        <ListGroup.Item key={a.id}> {a.answer}</ListGroup.Item>
                                                    ))
                                                }
                                            </ListGroup>
                                        </Row>
                                    </Modal.Body>
                                </Modal>
                            </Card.Body>
                        </Card>
                    }
                </Col>
            </Row>
        </Container>
    );
}


export default Detail;
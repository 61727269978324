import React, { useEffect } from "react";
import { Col, Container, Row, Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import TableSkeleton from "../../components/skeletons/tableSkeleton";
import { fetchData } from "../../store/event";
import { STAT_URL } from "../../utils";
import axios from '../../axios';
import { Link } from "react-router-dom";


const Index = () => {
  const state = useSelector((s) => s.Event);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData());
  
  }, []);

  const exportExcel = () => {
    axios({
      url: `${STAT_URL}/event/all_excel/get`,
      method: 'GET',
      responseType: 'blob',
    })
      .then((res) => {
        var FILE = window.URL.createObjectURL(new Blob([res.data]));
        var docUrl = document.createElement("a");
        docUrl.href = FILE;
        docUrl.setAttribute('download', `event.xls`);
        let add = document.body.appendChild(docUrl);
        add.click();
      })
  }

  return (
    <>
      {state && state.loading === true ? (
        <TableSkeleton />
      ) : (
        <Container fluid>
          <Row>
            <Col md={12} sm={12} className={"pt-5"}>
            <Button variant={"success"} style={{ float: 'right' }} onClick={() => exportExcel()}> Excele Aktar </Button>
              <Table className={'mt-5 table-striped'} responsive="md">
                <thead>
                  <tr>
                    <th>Başlık</th>
                    <th>Başlanıç Tarihi</th>
                    <th>Bitiş Tarihi</th>
                    <th>Yayında</th>
                    <th>Yakalayanlar</th>
                    <th>Kullananlar</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {state.data.map((data) => (
                    <tr key={data.id}>
                        <td>{data.title}</td>
                        <td>{data.start_date}</td>
                        <td>{data.end_date}</td>
                        <td>{data.status == 0 ? 'Hayır' : 'Evet'}</td>
                        <td>{data.stats.participation}</td>
                        <td>{data.stats.confirmed}</td>
                        <td>
                        <Link to={`event/${data.id}`}>
                          <Button variant='primary' size="sm">İncele</Button>
                        </Link>
                      </td>
                    </tr>
                ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Index;

import react, { useEffect, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import Chart from "react-google-charts";
const SurveyChart = ({ data, showButton, modal, id }) => {
  const [prepareData, setPrepareData] = useState([]);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (data) {
      setPrepareData(data);
      setStatus(true)
    }

  }, [data]);

  return (
    <Card className={'mt-5'}>
      <Card.Body>
        <Row>
          <Col md={6} sm={12}>
            {status && (
              <Chart
                width={"500px"}
                height={"300px"}
                chartType="PieChart"
                loader={<div>Loading Chart</div>}
                data={prepareData}
                options={{
                  title: "Katılımcılar",
                }}
                rootProps={{ "data-testid": "1" }}
              />
            )}



          </Col>
        </Row>
        {
          showButton &&
          <Button variant="primary" style={{ float: 'right' }} onClick={() =>modal(id,'chart')}>
            Detaylar
          </Button>
        }
      </Card.Body>
    </Card>
  );
};

export default SurveyChart;

import axios from "../axios";
import { AUTH_URL } from "../utils";
import CryptoJS from 'crypto-js';
import jwtEncode from 'jwt-encode';



function refreshToken(data) {
        axios.post(`${AUTH_URL}/auth/refresh`)
            .then((res) => {
                localStorage.setItem(
                    "appState",
                    CryptoJS.AES.encrypt(
                        jwtEncode(res.data, "secret"),
                        "dc29b87537ba333b0bd716c1ef03a97d"
                    ).toString()
                );

            })
  }

  export default refreshToken;
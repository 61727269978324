import axios from "axios";
import CryptoJS from "crypto-js";
import jwtDecode from "jwt-decode";
import jwtEncode from "jwt-encode";
import { AUTH_URL } from "./utils";


const appStateData = localStorage.getItem("appState");
if (appStateData) {
  var bytes = CryptoJS.AES.decrypt(
    appStateData,
    "dc29b87537ba333b0bd716c1ef03a97d"
  );
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  var data = jwtDecode(originalText);

  axios.interceptors.request.use(
    async config => {
      config.headers = {
        'Authorization': `Bearer ${data.access_token}`,
        'Accept': 'application/json',
      }
      return config;
    },
    error => {
      Promise.reject(error)
    });

}

axios.interceptors.response.use((response) => {
  return response
}, async function (error) {
  if (error.response.status === 401) {
    axios.post(`${AUTH_URL}/auth/refresh`)
      .then((res) => {
        localStorage.setItem(
          "appState",
          CryptoJS.AES.encrypt(
            jwtEncode(res.data, "secret"),
            "dc29b87537ba333b0bd716c1ef03a97d"
          ).toString()
        );
        window.location.reload();
      })

  }else {
      window.location.href='/login';
  }
  return Promise.reject(error);
});

export default axios;

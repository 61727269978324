import { createSlice, createAsyncThunk, rejectWithValue } from "@reduxjs/toolkit";
import axios from "../axios";
import { AUTH_URL } from "../utils";
import CryptoJS from "crypto-js";
import jwtDecode from "jwt-decode";
import jwtEncode from "jwt-encode";

export const login = createAsyncThunk("auth/login", async (data) => {
  const response = await axios.post(`${AUTH_URL}/auth/login`, data);
  return response;
});

export const me = createAsyncThunk("auth/me", async () => {
  try{
    const response = await axios.post(`${AUTH_URL}/auth/me`);
    return response;
  }catch(err){
  }
});

export const refresh = createAsyncThunk("auth/refresh", async () => {
  const response = axios.post(`${AUTH_URL}/auth/refresh`);
  return response;
});

const AuthSlice = createSlice({
  name: "Auth",
  initialState: {
    user: null,
    loginStatus: false,
    error: false,
    token: null,
  },
  reducers: {
    getToken(state) {
      const appStateData = localStorage.getItem("appState");
      if (appStateData) {
        var bytes = CryptoJS.AES.decrypt(
          appStateData,
          "dc29b87537ba333b0bd716c1ef03a97d"
        );
        var originalText = bytes.toString(CryptoJS.enc.Utf8);
        state.token = jwtDecode(originalText);
        state.loginStatus = true;
        state.error = false;
      }
    },
    removeToken(state) {
      state.loginStatus = null;
      state.user = null;
      localStorage.removeItem("appState");
    },
  },
  extraReducers: {
    [login.pending]: (state) => {},
    [login.fulfilled]: (state, action) => {
      localStorage.setItem(
        "appState",
        CryptoJS.AES.encrypt(
          jwtEncode(action.payload.data, "secret"),
          "dc29b87537ba333b0bd716c1ef03a97d"
        ).toString()
      );
      state.loginStatus = true;
    },
    [login.rejected]: (state) => {
      state.error = true;
    },
    [me.fulfilled]: (state, action) => {
      state.user = action.payload.data;
    },
    [me.rejected] : (state,action) => {
    },
    [refresh.fulfilled]: (state, action) => {
      state.loginStatus = true;
      localStorage.setItem(
        "appState",
        CryptoJS.AES.encrypt(
          jwtEncode(action.payload.data, "secret"),
          "dc29b87537ba333b0bd716c1ef03a97d"
        ).toString()
      );
    },
    [refresh.rejected]: (state) => {
      state.user = null;
      state.loginStatus = false;
      state.error = true;
    },
  },
});

export const { getToken, removeToken } = AuthSlice.actions;

export default AuthSlice.reducer;

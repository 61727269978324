import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Router from "./router/router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getToken } from "./store/auth";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getToken());
  }, []);
  return <Router />;
}

export default App;
